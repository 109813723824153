<template>
  <router-link
    :to="{
      name: 'Songs',
      params: { id: playlist.id },
    }"
    class="h-full overflow-hidden flex flex-col"
  >
    <img :src="playlist.image" :alt="playlist.name" class="rounded-t w-full" />
    <div class="p-2 lg:p-4 bg-slate-800 flex-1 rounded-b">
      <h3 class="font-semibold capitalize">
        {{ playlist.name }}
      </h3>
      <p class="hidden lg:block text-sm text-slate-400">
        {{ playlist.description }}
      </p>
    </div>
  </router-link>
</template>

<script>
export default {
  props: ["playlist"],
};
</script>

<style></style>
