<template>
  <div>
    <Navbar />

    <div class="px-4 lg:px-2 py-8 2xl:py-10 md:px-12 flex-1">
      <h1 class="text-2xl 2xl:text-4xl font-semibold mb-4">Playlist</h1>

      <div
        class="grid grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-2 md:gap-3 2xl:gap-8"
        v-if="playlists"
      >
        <div v-for="playlist in playlists" :key="playlist.id">
          <CardPlaylist
            :playlist="playlist"
          />
        </div>
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import Footer from "@/components/Footer.vue";
import CardPlaylist from "@/components/CardPlaylist.vue";

import { getClient } from "@/includes/api";

export default {
  components: { Navbar, Footer, CardPlaylist },
  data() {
    return {
      playlists: [],
    };
  },
  async mounted() {
    try {
      let apiClient = getClient();
      let playlists = await apiClient.getPlaylists();
      if (Array.isArray(playlists)) {
        this.playlists = playlists;
      }
    } catch (err) {
      console.error(err);
    }
  },
};
</script>
